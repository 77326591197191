define(['app'], (app) => {

  const sortFilters = () => {
    const component = {};

    const _config = {
      selectors: {
        filter: '.sortFilters_filter',
        checkIcon: '.sortFilters_filterCheckIcon'
      },
      attrs: {
        selected: 'data-selected',
        value: 'data-value',
        ariaSelected: 'aria-selected',
        category: 'data-facet-category'
      },
      channels: {
        update: 'responsiveProductListPage/update'
      }
    };

    const GA_EVENT_CATEGORY = 'Facet Engagement';
    const GA_EVENT_ACTION_SELECT = 'Selects';
    const GA_EVENT_ACTION_FACET_NAME = 'Sort By';

    const _init = (element) => {
      component.element = element;
      component.sortFilters = Array.from(component.element.querySelectorAll(component.config.selectors.filter));

      component.addEventListeners();
      return component;
    };

    const _addEventListeners = () => {
      for (let sortFilter of component.sortFilters) {
        sortFilter.addEventListener('click', component.sortFilterInteraction.bind(this));
        sortFilter.addEventListener('keydown', component.triggerSortFilter.bind(this));
      }
    };

    const _triggerSortFilter = (e) => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        component.sortFilterInteraction(e);
      }
    };

    const _sortFilterInteraction = (e) => {
      let sortFilter = e.target;
      if (!sortFilter.hasAttribute(component.config.attrs.selected)) {
        component.resetStyleOfCurrentSelection();
        component.modifyStyles(sortFilter);
        component.publishChanges(sortFilter.getAttribute(component.config.attrs.value))
      }
    };

    const _modifyStyles = (sortFilter) => {
      sortFilter.setAttribute(component.config.attrs.selected, '');
      sortFilter.setAttribute(component.config.attrs.ariaSelected, 'true');
      sortFilter.querySelector(component.config.selectors.checkIcon)
        .setAttribute(component.config.attrs.selected, '');
    };

    const _resetStyleOfCurrentSelection = () => {
      let selectedFilter = component.sortFilters
        .filter(filter => filter.hasAttribute(component.config.attrs.selected))[0];
      selectedFilter.removeAttribute(component.config.attrs.selected);
      selectedFilter.setAttribute(component.config.attrs.ariaSelected, 'false');
      selectedFilter.querySelector(component.config.selectors.checkIcon)
        .removeAttribute(component.config.attrs.selected);
    };

    const _publishChanges = (value) => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_SELECT} ${GA_EVENT_ACTION_FACET_NAME}`, value);
      app.publish(component.config.channels.update, {sortOrder: value});
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.sortFilterInteraction = _sortFilterInteraction;
    component.publishChanges = _publishChanges;
    component.modifyStyles = _modifyStyles;
    component.resetStyleOfCurrentSelection = _resetStyleOfCurrentSelection;
    component.triggerSortFilter = _triggerSortFilter;

    return component;
  };

  return sortFilters;
});
